<template>
  <div class="radio-group-view">
    <esmp-radio
      v-for="(item, index) in values"
      :key="index"
      disabled
      name="radio"
      :label="item.name"
      :value="item.name"
      v-model="value"
    />
  </div>
</template>

<script>
export default {
  name: 'RadioGroupView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    values() {
      return this.settings.find((el) => el.techName === 'values').values;
    },
  },
};
</script>

<style lang="scss">
.radio-group-view {
  display: flex;
  flex-direction: column;
}
</style>
